import React from 'react';
import { Link, useRouteError } from "react-router-dom"

export default function RootError() {
    const error = useRouteError()

    return (
        <div className="home-error">
            <p>Oops - Something seems to have gone wrong!</p>
            <p>{error.message}</p>
            <Link to="/home">Back to the Homepage</Link>
        </div>
    )
}