import React, { useState, useEffect } from 'react';
import { Outlet} from "react-router-dom"
import {

    Box,
    Button,
    Grommet,
    Heading,
    Main,
    Layer,

    grommet,
} from 'grommet';
import { Menu } from 'grommet-icons';

import { deepMerge } from 'grommet/utils';
import { FormClose } from 'grommet-icons';

import { MSCStatus } from '../MSCStatus.js';



import uslogo from '../assets/large-transparent-logo.png'
import smlogo from '../assets/logo.jpg';
import styled, { css } from "styled-components";
import "../index.css";


const understory = true;
let logo;

if(understory){
    logo=uslogo;
}else{
    logo=smlogo;
}


/* Grommet styling */
const MainCont = styled(Main)`
    height:auto;
`

const PanelBox = styled(Box)`
    background-color: #efefdf;
    padding: 20px 10px;
    margin:10px;
    @media only screen and (max-width: 768px){
        margin: 6px;
    }
`;

const NavBox = styled(Box)`
    width: 20rem;
    min-height: 15rem;
    position: absolute;
    top: 0px;
    right: 0px;
    background: #e3e2df;
    font-size: 1.3rem;
    text-align: right;
    padding: 0.5rem 0.5rem 1rem;
    overflow: hidden;
    button{
        padding:0px;
    }

`;



const theme = deepMerge(grommet, {
    global: {
        colors: {
            background: '#faf9f4',
            link: "#35d4f8",
            text: "#464b4b",
            focus: "#e1dc4c",
            control: "#e1dc4c",
            icon: "#d3cf1b"
        },
    },
    "textInput": {
        extend: ({ theme }) => css`
            background-color: #ffffff;
            font-family: Rational;
        `
    },
    "checkBox": {
        extend: ({ theme }) => css`
            > div > div{
                background-color: #ffffff;
            }
        `
    },
    "grommet": {
        extend: ({ theme }) => css`
            font-family: Cereal;
        `
    },
    "formField": {
        extend: ({ theme }) => css`
            > div {
                border: none;
            }
        `
    }

});

const AppBar = (props) => (
    <Box
        tag='header'
        direction='row'
        pad={{ left: 'small', right: 'small', vertical: 'small' }}
        align='center'
        justify='between'
        flex={false}
        {...props}
    />
);

const Sidebar = (props) => (
    <MSCStatus
        slug={props.slug}
        user={props.user}
    />
);

export default function Root(props) {
    const [showSidebar, setShowSidebar] = useState(false);

    return (
        <Grommet theme={theme} full>

            <MainCont>
                <AppBar>
                    <Heading level='3' margin='none'>
                        <img align="middle" width={200} src={logo} />
                    </Heading>
                    <Button
                        icon={<Menu color="icon" size="large" />}

                        pad="none"
                        plain="true"
                        onClick={() => setShowSidebar(!showSidebar)}
                    />
                </AppBar>

                <PanelBox >
                    <Outlet />
                </PanelBox>

                {showSidebar && (
                    <NavBox animation="['fadeIn', 'fadeOut']">
                        <Box
                            tag='header'
                        >
                            <Button
                                icon={<Menu pad="none" color="icon" size="large" />}
                                onClick={() => setShowSidebar(false)}
                            />
                        </Box>
                        <Box align='center' justify='center'>

                              <Sidebar
                                slug={props.projectSlug}
                                user={props.user}
                            />
                        </Box>
                    </NavBox>
                 )}
            </MainCont>
        </Grommet>  
  )
}


