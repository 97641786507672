import React from 'react';
import { Anchor, Box, Heading, Text } from 'grommet';
import PropTypes from 'prop-types';

import { AGClientSocket } from 'socketcluster-client';
import styled, { css } from "styled-components";

const stateFormatter = new Map();
stateFormatter.set(AGClientSocket.CONNECTING, 'connecting');
stateFormatter.set(AGClientSocket.OPEN, 'connected');
stateFormatter.set(AGClientSocket.CLOSED, 'not connected');

const NavAnchor= styled(Anchor)`
	font-weight:normal;
	padding-bottom:8px;
	:hover{
		transform: translate(-0.5rem, 0);
		text-decoration: none;
	}
	:focus-visible{
		outline-offset: 0px;
		outline: none;
	}
	:focus{
		box-shadow: none;
	}
`


export const MSCStatus = (props) => {
	console.log(props.slug);
	return (
		<Box>
			<Box pad={{ top: "20px" }}>
				<NavAnchor color="text" href={`${window.location.href.split('?')[0]}`} label="Home"/>
				<NavAnchor color="text" href={`${window.location.href.split('?')[0]}/network`} label="View Map"/>
				{props.user && (
					<>
						<NavAnchor color="text" href='/logout' label='Log out' />
					</>
				)}
				<Box pad="10px">
					<Text size="small" wordBreak="break-word">Project:{"" === props.slug || "404" === props.slug ? "No Project" : props.slug}</Text>
					<Text size="small" wordBreak="break-word">User:{props.user?.email || 'No user'}</Text>
				</Box>
			</Box>
		</Box>
	);
};

MSCStatus.propTypes = {
	socket: PropTypes.object,
	slug: PropTypes.string,
	datasetID: PropTypes.string,
};
