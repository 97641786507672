import React, { useState, useEffect } from 'react';
import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
    RouterProvider, 
    Navigate
} from 'react-router-dom'

import socketClusterClient from 'socketcluster-client';

// pages
import Home from './pages/Home'
import Forms from './pages/Forms'
import Error from './pages/Error'
// layouts
import Root from './pages/Root';

import GA4React from "ga-4-react";

try {
    setTimeout(_ => {
        const ga4react = new GA4React("G-LN540ZW851");
        ga4react.initialize().catch(err => console.error(err));
    }, 4000);
} catch (err) {
    console.error(err);
}

function App() {
    const [socket, setSocket] = useState(null);
    const [user, setUser] = useState();
    const [admin, setAdmin] = useState();
    const [projects, setProjects] = useState(null);
    const [projectSlug, setProjectSlug] = useState('');

    useEffect(() => {
        const options = process.env.NODE_ENV === 'production' ? {} : { port: 9000 };
        const s = socketClusterClient.create(options);

        (async () => {
            for await (let { error } of s.listener('error')) {
                console.error('Socket error: ', error);
                setProjectSlug('404');
            }
        })();

        (async () => {
            for await (let event of s.listener('connect')) {
                console.log('Socket is connected');
                setSocket(s);

                const response = await fetch('/user', { credentials: 'same-origin' });
                if (response.ok) {
                    const json = await response.json();
                    if (!s.authToken || s.authToken.id !== json.authToken.id) {
                        await s.authenticate(json.signedAuthToken);
                    } else {
                        console.log('Socket authentication still valid');
                    }
                    setUser(json.userInfo); // Always trigger get project attempt
                    setAdmin(json.authToken.admin);
                    setProjects(json.authToken.projects);
                    console.log(projects);
                } else if (response.status === 403) {
                    s.deauthenticate();
                    setUser();
                    // If we're on a project page and so require auth, reload to trigger session authentication
                    // If we're not, don't as otherwise it'll refresh infinitely.
                    if (window.location.pathname !== '/') {
                        window.location.reload();
                    }
                } else {
                    console.error('Could not fetch /user');
                    setProjectSlug('404');
                }
            }
        })();

        (async () => {
            for await (let event of s.listener('authenticate')) {
                console.log('Socket is authenticated. ', event.authToken);
            }
        })();

        return () => {
            s.disconnect();
        };
    }, []);




    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route path="/" element={<Root
                user={user}
                projectSlug={projectSlug}

            />}
                errorElement={< Error />}
            >
                <Route index element={<Navigate to="home" />} />
                <Route path="home" element={<Home projects={projects}/>}
                    errorElement={< Error />}
                />
                <Route path=":projectName"
                    element={<Forms
                        socket={socket}
                        setSocket={setSocket}
                        user={user}
                        setUser={setUser}
                        admin={admin}
                        setAdmin={setAdmin}
                        projects={projects}
                        setProjects={setProjects}
                        projectSlug={projectSlug}
                        setProjectSlug={setProjectSlug}
                    />}
                    errorElement={<Error />}
                />
            </Route>
        )
    )

    return (
        <RouterProvider router={router} />
    );
}

export default App
