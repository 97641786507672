import React from 'react';
import { useRouteError } from "react-router-dom"
import {

    Box,
    Button,

    Paragraph,

} from 'grommet';
import styled from "styled-components";

const ControlBtn = styled(Button)`
    background-color: #d3cf1b;
            color: #464b4b;
            border: 2px solid #464b4b;  
            border-radius: 12px;
            text-transform: uppercase;
            &:hover{
                background-color: #d3cf1b;
                color: #464b4b;
                border: 2px solid #ffffff; 
                box-shadow:none;
            }
`;


const LoadBox = styled(Box)`
    width:fit-content;
`;

export default function RootError() {
    return (
        <>
        <Paragraph> Please wait a moment as the page loads, if it takes longer than expected please click the button below.
        </Paragraph>
        <LoadBox>
            <ControlBtn
                primary
                label='Reload'
                onClick={(e) => {
                    window.location.reload();
                }}
            />
            </LoadBox>
            </>
    )
}