import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useDeepCompareEffectNoCheck as useEffectDeepCompare } from 'use-deep-compare-effect';
import {
  Box,
  Form,
  FormField,
  RangeInput,
  Text,
  TextInput,
  Spinner,
} from 'grommet';
import PropTypes from 'prop-types';
import Ajv from 'ajv';
import { Wrapper, Status } from '@googlemaps/react-wrapper';
import { translateQuestion, translate } from '../../translate';

const jsonSchemaValidator = new Ajv();

// A google map with a marker
export const Map = (props) => {
  // MARK - Form handling

  let marker = useRef();
  let map = useRef();

  let mapComponent;
  const [validator, setValidator] = useState((state) => () => false);

  const [values, setValues] = useState('');

    const [errorMessage, setErrorMessage] = useState(
        translate('forms.map.error', null, props.languageDirectory)
  );

  useEffectDeepCompare(() => {
    props.onValid(false, props.id);
    try {
      if (props.submit[0]) {
        props.onValid(true, props.id);
        setErrorMessage('');
      }
    } catch (e) {}
    setValues(props.submit ? props.submit : '');
  }, [props.id, props.submit]);

  mapComponent = useMemo(
    () => <MyMapComponent setValues={setValues} values={values} />,
    [values]
  );

  const updatePosition = (newValue) => {
    map.current.panTo(newValue);
    const submitValues = [newValue.lat() + '|' + newValue.lng()];
    console.log('Updating position');
    // Validate
    const isValid = true;

    // Pass up to App
    props.onValid(isValid, props.id);

    if (isValid) {
      props.onData(submitValues, props.id);
      setErrorMessage('');
    } else {
      // const messages = validator.errors
      //   ? validator.errors.map((error) => {
      //       if (error.schemaPath === '#/items/type') {
      //         return 'Please enter a number';
      //       }
      //       if (error.schemaPath === '#/items/minimum') {
      //         return `Please enter a number, ${error.params.limit} or greater`;
      //       }
      //       console.warn('Unhandled validation error: ', error);
      //       return error.message;
      //     })
      //   : ['Failed validation'];
      // setErrorMessage('Please sort it');
    }
  };
  // useEffectDeepCompare(() => {
  //   const submitValues = values;

  //   // Validate
  //   const isValid = true;

  //   // Pass up to App
  //   props.onValid(isValid, props.id);

  //   console.log('Values changed', values, marker.current);
  //   if (values && marker.current) {
  //     try {
  //       const bits = values[0].split('|');
  //       if (bits.length == 2) {
  //         const markerPosition = new window.google.maps.LatLng(
  //           bits[0],
  //           bits[1]
  //         );
  //         marker.current.setPosition(markerPosition);
  //       }
  //     } catch (err) {
  //       console.log('Failed restoring marker position', err);
  //     }
  //   }
  //   // Act on validation
  //   // Ideally, this would only display an error if a form field is touched.
  //   if (isValid) {
  //     props.onData(submitValues, props.id);
  //     setErrorMessage('');
  //   } else {
  //     // const messages = validator.errors
  //     //   ? validator.errors.map((error) => {
  //     //       if (error.schemaPath === '#/items/type') {
  //     //         return 'Please enter a number';
  //     //       }
  //     //       if (error.schemaPath === '#/items/minimum') {
  //     //         return `Please enter a number, ${error.params.limit} or greater`;
  //     //       }
  //     //       console.warn('Unhandled validation error: ', error);
  //     //       return error.message;
  //     //     })
  //     //   : ['Failed validation'];
  //     setErrorMessage('Please sort it');
  //   }
  // }, [values]);

  // MARK - JSX

  const helpText = () => {
    if (props.minValue === undefined) {
      if (props.maxValue === undefined) {
        return '';
      }
        return translate('forms.map.help_text_min', { max: props.maxValue }, props.languageDirectory);
    }
    if (props.maxValued === undefined) {
      if (props.minValue === 0) {
          return translate('forms.map.help_min_zero', null, props.languageDirectory);
      }
        return translate('forms.map.help_text_max', { min: props.minValue }, props.languageDirectory);
    }
      return translate('forms.map.help_text', { min: props.minValue, max: props.maxValue }, props.languageDirectory);
  };

  function getDefaultMapPosition() {
    if (props.projectConfig && props.projectConfig.map) {
      let { lat, long } = props.projectConfig.map;
      return new window.google.maps.LatLng(lat, long);
    }

    // just a hardcoded default to Berlin, because why not...
    return new window.google.maps.LatLng(52.4852243, 13.4355735);
  }

  function getDefaultMapZoom() {
    if (props.projectConfig && props.projectConfig.map) {
      return props.projectConfig.map.zoom;
    }

    // just a hardcoded default to Berlin, because why not...
    return 16;
  }

  function MyMapComponent({ center, zoom, setValues, values }) {
    const ref = useRef();
    const formRef = useRef();
    useEffect(() => {
      const ll = getDefaultMapPosition();
      let markerPosition = ll;

      console.log(
        'About to create marker',
        ll.lat(),
        ll.lng(),
        getDefaultMapZoom(),
        values
      );
      if (values) {
        console.log('Doing this');
        try {
          const bits = values[0].split('|');
          if (bits.length === 2) {
            markerPosition = new window.google.maps.LatLng(bits[0], bits[1]);
          }
        } catch (err) {
          console.log('Failed restoring marker position', err);
        }
      }

      map.current = new window.google.maps.Map(ref.current, {
        center: markerPosition,
        zoom: getDefaultMapZoom(),
        mapTypeId: window.google.maps.MapTypeId.ROADMAP,
        styles: [
          {
            featureType: 'poi',
            stylers: [{ visibility: 'off' }],
          },
          {
            featureType: 'transit',
            stylers: [{ visibility: 'off' }],
          },
        ],
      });

      marker.current = new window.google.maps.Marker({
        position: markerPosition,
        map: map.current,
        draggable: true,
        title: translate('forms.map.marker_title', null, props.languageDirectory),
      });

      function handleEvent(event) {
        let error =
          formRef.current.childNodes[formRef.current.childNodes.length - 1];
        if (error && error.nodeName.toLowerCase() == 'span') {
          error.innerText = '';
        }

        //

        updatePosition(
          new window.google.maps.LatLng(event.latLng.lat(), event.latLng.lng())
        );
      }

      marker.current.addListener('dragend', handleEvent);
    });

    console.log('Rnedering map component', errorMessage);

    return (
      <Form>
        <FormField
          ref={formRef}
          label={translateQuestion(props)}
          error={errorMessage}
          help={props.helpText}
        >
          <Box pad={{ horizontal: 'small', vertical: 'xsmall' }} gap='small'>
            <Box pad={{ horizontal: 'small', vertical: 'xsmall' }} gap='small'>
              <div ref={ref} id='map' />
            </Box>
          </Box>
        </FormField>
      </Form>
    );
  }

  const render = (status) => {
    switch (status) {
      case Status.LOADING:
        return <Spinner />;
      case Status.FAILURE:
        return <p>Something went wrong initialising the map.</p>;
      case Status.SUCCESS:
        return <>{mapComponent}</>;
    }
  };
  return (
    <Wrapper
      apiKey={'AIzaSyDMQE6rdbNXRYC0IaUMM-9ravsL9eF3x4o'}
      render={render}
    ></Wrapper>
  );
};

Map.propTypes = {
  id: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
  fieldLabels: PropTypes.arrayOf(PropTypes.string),
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  onData: PropTypes.func,
  long: PropTypes.number,
  lat: PropTypes.number,
  zoom: PropTypes.number,
  projectConfig: PropTypes.object,
};
