const DEFAULT_VALUE = '';

export function findTranslation(path, translations) {
    const chunks = path.split(".");
    let result = translations;
    for (const chunk of chunks) {
        if (!result[chunk]) {
            return DEFAULT_VALUE;
        }
        result = result[chunk];
    }
    return typeof result === 'string' || Array.isArray(result) ? result : DEFAULT_VALUE;
}



export const translate = (key, replacements, directory) => {
    const translated = findTranslation(key, directory) || key;
    console.log(translated);
    if (replacements === null || Object.keys(replacements).length === 0) {
        //needs to interate through if there are '.'
        return translated;
    }

    return interpolateTranslate(translated, replacements);

}
//artem you will need to update this with your function 
export const interpolateTranslate = (input, replacements) => {
    const strings = Array.isArray(input) ? input : [input];
    const result = strings.map((str) => {
      Object.keys(replacements).forEach((key) => {
        const value = replacements[key];
        str = str.replaceAll(`{{${key}}}`, value);
      })
      return str;
    });
    return Array.isArray(input) ? result : result[0];
}



//just for questions
export const translateQuestion = (question) => {
     console.log('qsl', question);
    if (question.defaultLanguage === 'default' || question.defaultLanguage === undefined || question.languageOptions[question.selectedLangauge] === question.defaultLanguage) {
         //return normal
         console.log('default value');
         return question.label;
     } else {

         if (question.lang.label[question.selectedLanguage]) {
             return question.lang.label[question.selectedLanguage];
         } else {
             return question.label;
         }


     }
}

export const translateQuestionValue = (question, key, value) => {
    //console.log(question, key, value);
    if (question.defaultLanguage === 'default' || question.defaultLanguage === undefined || question.languageOptions[question.selectedLangauge] === question.defaultLanguage) {
        return value;
    } else {
        if (question.lang[key][value][question.selectedLanguage]) {
            return question.lang[key][value][question.selectedLanguage];
        } else {
            return value;
        }
    }
}

export const getTranslationsFor = async (language) => {
    console.log('getTranslationsFor called with', language);
    try {
      const response = await fetch(`/languages/${language}`, {
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error(`Request to /languages/${language} endpoint failed with status code ${response.status}`);
      }

      const { translations } = await response.json();

      if (!translations) {
        throw new Error("Property 'translations' is missing on response object");
      }
      return translations;
    } catch (error) {
      console.error("Could not fetch /languages/%s: %s", language, error);
      return null;
    }
}
