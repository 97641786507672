import React from 'react';
import PageLoader from './PageLoader';
import {
    Anchor,
    Paragraph,
} from 'grommet';
import { Helmet } from 'react-helmet';


export default function Home(props) {
    return (

        <>
            <Helmet>
                <title>
                    HomePage
                </title>
            </Helmet>
            {props.projects === null ? (<PageLoader />) :
                (<>
                    <Paragraph>
                        Oops - it looks like you're not where you want to be!
                    </Paragraph>
                    {props.projects.length == 1 &&
                        <><Paragraph>
                            It looks like you are a member of {props.projects[0]} you can use the links below to open the page you want
                        </Paragraph>
                            <Anchor color="link"
                                href={'/' + props.projects[0]}
                                label={props.projects[0] + ' survey'}
                            />
                            <Anchor color="link"
                                href={'/' + props.projects[0] + '/network'}
                                label={props.projects[0] + ' network'}
                            />
                            <Paragraph>
                                If this is not what you are looking for you can either get in touch with your community manager or with the Understory team at <b>users@understory.community</b>
                            </Paragraph></>
                    }
                    {props.projects.length >= 2 &&
                        <>
                            <Paragraph>
                                It looks like you are a member of more than one community. You can use one of  the following buttons to open the page you want.
                            </Paragraph>

                            {props.projects.map(p => (
                                <>
                                    <ul>
                                        <li>
                                            <Anchor color="link"
                                                href={'/' + p}
                                                label={p + ' survey'}
                                            />
                                        </li>
                                        <li>
                                            <Anchor color="link"
                                                href={'/' + p + '/network'}
                                                label={p + ' network'}
                                            />
                                        </li>
                                    </ul>
                                </>
                            )
                            )}

                            <Paragraph>
                                If this is not what  you are looking for you can either get in touch with your community manager or with the Understory team here at <b>users@understory.community</b>
                            </Paragraph>
                        </>
                    }
                    {props.projects.length == 0 &&
                        <>
                            <Paragraph>
                                If you have been invited to take part in a community mapping, you should get in touch with your community manager. If that's not the case or if you just want to say hello to us theny you can get in touch with the Understory team at <b>users@understory.community</b>
                            </Paragraph>
                        </>
                    }

                </>)
            }
    </>
  )
}
