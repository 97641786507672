import React, { useState } from 'react';
import { useDeepCompareEffectNoCheck as useEffectDeepCompare } from 'use-deep-compare-effect';
import { Box, Form, FormField, RangeInput, Text, TextInput } from 'grommet';
import PropTypes from 'prop-types';
import Ajv from 'ajv';
import { translateQuestion } from '../../translate';

const jsonSchemaValidator = new Ajv();

// A question with a single or multiple quantifiable answer
export const Quant = (props) => {
  // MARK - Form handling

  const [validator, setValidator] = useState((state) => () => false);

  useEffectDeepCompare(() => {
    const schema = {
      type: 'array',
      items: {
        type: 'integer',
      },
      minItems: 1,
      maxItems: (props.fieldLabels && props.fieldLabels.length) || 1,
    };
    if (props.minValue !== undefined) {
      schema.items.minimum = parseInt(props.minValue, 10);
    }
    if (props.maxValue !== undefined) {
      schema.items.maximum = parseInt(props.maxValue, 10);
    }
    setValidator((state) => jsonSchemaValidator.compile(schema));
  }, [props.minValue, props.maxValue, props.fieldLabels]);

  const [values, setValues] = useState({});

  useEffectDeepCompare(() => {
    const keys = Array.from(
      { length: props?.fieldLabels?.length || 1 },
      (_, idx) => `${props.id}-numberInput${idx}`
    );
    const previousSubmitValues = validator(props.submit)
      ? props.submit.values()
      : [].values();
    setValues(
      Object.fromEntries(
        keys.map((key) => [key, previousSubmitValues.next().value || ''])
      )
    );
  }, [props.id, props.fieldLabels, props.submit, validator]);

  const [errorMessage, setErrorMessage] = useState('');

  useEffectDeepCompare(() => {
    // Transform values
    //    {numberInput0: '123', numberInput1: '456', ...}
    // into array of values
    //    [123, 456, ...]
    const submitValues = Object.values(values).map((x) => parseInt(x, 10));

    // Validate
    const isValid = validator(submitValues);

    // Pass up to App
    props.onValid(isValid, props.id);

    // Act on validation
    // Ideally, this would only display an error if a form field is touched.
    if (isValid) {
      props.onData(submitValues, props.id);
      setErrorMessage('');
    } else {
      const messages = validator.errors
        ? validator.errors.map((error) => {
            if (error.schemaPath === '#/items/type') {
              return 'Please enter a number';
            }
            if (error.schemaPath === '#/items/minimum') {
              return `Please enter a number, ${error.params.limit} or greater`;
            }
            console.warn('Unhandled validation error: ', error);
            return error.message;
          })
        : ['Failed validation'];
      setErrorMessage(messages.join(' · '));
    }
  }, [values]);

  // MARK - JSX

  const helpText = () => {
    if (props.minValue === undefined) {
      if (props.maxValue === undefined) {
        return '';
      }
      return `Please enter a number, up to ${props.maxValue}`;
    }
    if (props.maxValued === undefined) {
      if (props.minValue === 0) {
        return 'Please enter a number, zero or greater';
      }
      return `Please enter a number, ${props.minValue} or greater`;
    }
    return `Please enter a number between ${props.minValue} and ${props.maxValue}`;
  };

  return (
    <Form value={values} onChange={(nextValues) => setValues(nextValues)}>
          <FormField label={translateQuestion(props)} error={errorMessage} help={helpText()}>
        <Box pad={{ horizontal: 'small', vertical: 'xsmall' }} gap='small'>
          {Object.entries(values)
            .filter(
              (x, idx) =>
                !props.fieldLabels || props.fieldLabels[idx] !== '__hide__'
            )
            .map(([key, value], idx) => (
              <React.Fragment key={key}>
                {props.fieldLabels && (
                  <label htmlFor={key}>{props.fieldLabels[idx]}</label>
                )}
                {props.minValue !== undefined && props.maxValue !== undefined && (
                  <Box direction='row' pad='none'>
                    <Text size='small'>{props.minValue}</Text>
                    <RangeInput
                      min={parseInt(props.minValue, 10)}
                      max={parseInt(props.maxValue, 10)}
                      name={key}
                    />
                    <Text size='small'>{props.maxValue}</Text>
                  </Box>
                )}
                {!(
                  props.minValue !== undefined && props.maxValue !== undefined
                ) && <TextInput type='number' name={key} value={value} />}
              </React.Fragment>
            ))}
        </Box>
      </FormField>
    </Form>
  );
};

Quant.propTypes = {
  id: PropTypes.any.isRequired,
  label: PropTypes.string.isRequired,
  fieldLabels: PropTypes.arrayOf(PropTypes.string),
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  onData: PropTypes.func,
};
